<template>
    <div class="project-create">
        <div class="manage-wrapper">
            <div class="project-create-container">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="编辑报告" name="first">
                        <el-form ref="createForm" :model="form" label-width="180px" :rules="rules">
                            <el-form-item label="报告名称：" prop="title" required>
                                <el-input v-model="form.title" maxlength="100" show-word-limit style="width:400px">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="模型：" prop="aiModel">
                                <el-radio-group v-model="form.aiModel">
                                    <el-radio v-for="(type, index) in aiModelList" :key="index + type.label"
                                        :label="type.value">
                                        {{ type.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="分类：" prop="type" required>
                                <el-radio-group v-model="form.type">
                                    <el-radio v-for="(type, index) in typeOptions" :key="index + type.label"
                                        :label="type.value">
                                        {{ type.label }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="类型：" prop="type2" v-if="form.type == '-1'">
                                <el-radio-group v-model="form.type2">
                                    <el-radio v-for="(type, index) in typeOptions[0].types" :key="index + type.label"
                                        :label="type.value">
                                        {{ type.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="选择周：" v-if="form.type != '-1'">
                                <el-select v-model="weekVal" placeholder="请选择周" style="margin-bottom: 10px;"
                                    @change="selectWeek">
                                    <el-option v-for="item in weekList" :key="item.cycleId + item.cycleName"
                                        :label="item.cycleName" :value="item.cycleId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="报告角色：" prop="roleIds" required v-if="form.type != '-1'">
                                <el-checkbox :indeterminate="form.isIndeterminate" v-model="form.checkAll"
                                    @change="handleCheckAllChange">全选</el-checkbox>
                                <el-checkbox-group v-model="form.roleIds" @change="handleCheckedRolesChange">
                                    <el-checkbox v-for="role in rolesOptions" :label="role.id" :key="role.id">
                                        {{ role.label }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="模块1：" v-if="form.type == 3 || form.type == 0">
                                <el-checkbox v-model="form.checkPhase">我的观思问</el-checkbox>
                                <div v-if="form.checkPhase">
                                    观思问时间：<br>
                                    <el-date-picker v-model="form.phaseDateTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                    <br>
                                    提交时间：<br>
                                    <el-date-picker v-model="form.phaseSubmitteTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="模块2：" v-if="form.type == 3 || form.type == 0">
                                <el-checkbox v-model="form.checkQuest">我的作业</el-checkbox>
                                <div v-if="form.checkQuest && workList.length > 0">
                                    <el-transfer v-model="form.worksIds" :data="workList" :titles="['全部作业', '已选作业']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按作业名称搜索"
                                        :props="{ key: 'questId', label: 'questName' }">
                                        <!-- <span slot-scope="{ option }">{{ option.name }} - {{ option.phone }}</span> -->
                                    </el-transfer>
                                    作业时间：<br>
                                    <el-date-picker v-model="form.questDateTime" type="datetimerange" range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期">
                                    </el-date-picker>
                                    <br>
                                    提交时间：<br>
                                    <el-date-picker v-model="form.questSubmitteTime" type="datetimerange"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="前测/后测报告：" v-if="form.type == '-1'">
                                <el-checkbox v-model="form.test">前测</el-checkbox>
                                <div v-if="form.test">
                                    <el-transfer v-model="form.testIds" :data="testList" :titles="['全部前测', '已选前测']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按前测名称搜索"
                                        :props="{ key: 'activityId', label: 'title' }">
                                        <template #default="{ option }">
                                            <el-tooltip :content="option.title" placement="bottom" effect="light">
                                                <span class="transfer-item">{{ option.title }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-transfer>
                                </div>
                            </el-form-item>
                            <el-form-item label="报告：" v-if="form.type == 1">
                                <el-checkbox v-model="form.checkReport">报告</el-checkbox>
                                <div v-if="form.checkReport">
                                    <el-transfer v-model="form.reportIds" :data="reportList" :titles="['已有报告', '已选报告']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按前测名称搜索"
                                        :props="{ key: 'id', label: 'title' }">
                                        <template #default="{ option }">
                                            <el-tooltip :content="option.title" placement="bottom" effect="light">
                                                <span class="transfer-item">{{ option.title }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-transfer>
                                </div>
                            </el-form-item>
                            <!-- 所属项目与用户组 -->
                            <UserGroup v-if="form.type == 3" :id="Number($store.state.user.currentProject.id)" isEdit
                                @userGroup="changeUserGroup" :isBoutique="form.type" :userGroupVal="userGroupVal">
                            </UserGroup>
                            <el-form-item label="AI模板：" prop="description" required>
                                <el-select v-model="modelVal" placeholder="请选择模板" style="margin-bottom: 10px;"
                                    @change="selectChange">
                                    <el-option v-for="item in modelList" :key="item.templateType + item.projectId"
                                        :label="item.title" :value="item.templateType">
                                    </el-option>
                                </el-select>
                                <br>
                                <el-input class="textarea" type="textarea" :rows="7" resize="none" placeholder="生成报告描述"
                                    v-model="form.description"  show-word-limit>
                                </el-input>
                            </el-form-item>
                            <!-- 按钮组 -->
                            <el-form-item class="el-form-item-btns" label-width="0px">
                                <el-button @click="$router.push('/home/totalReport')">取消</el-button>
                                <el-button type="primary" @click="onSubmit">确定</el-button>
                            </el-form-item>
                            <br />
                            <br />
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import upload from './upload-file.vue';
export default {
    components: {
        upload
    },
    data() {
        return {
            activeName: "first",
            form: {
                projectId: null, //项目id
                title: null, // 报告名称
                type: null, // 报告类型  0周总结 1盟市总结 2教委总结 3个人总结
                type2: null,
                roleIds: [], // 角色
                checkPhase: null, // 观思问模块选择  0未选择 1已选择
                phaseDateTime: [], // 观思问时间
                phaseSubmitteTime: [], // 观思问答题开始时间
                checkQuest: null, // 作业块选择  0未选择 1已选择
                questDateStart: null, // 作业开始时间
                questDateEnd: null, // 作业结束时间
                userGroupIds: null, // 用户组id
                description: null, // AI描述
                checkAll: false,
                isIndeterminate: true,
                worksIds: [], // 穿梭框选择的作业id
                test: false,
                testIds: [], // 穿梭框选择的前测id
                questDateTime: [], // 作业时间
                questSubmitteTime: [],// 作业提交时间
                checkReport: null, // 报告模块选择 0未选择 1已选择
                reportIds: [], // 穿梭框选择的报告id
                aiModel:null
            },
            // 表单验证规则
            rules: {
                title: [{
                    required: true,
                    message: "请填写报告名称",
                    trigger: "blur"
                }],
                type: [{
                    required: true,
                    message: "请选择报告分类",
                    trigger: "change"
                }],
                type2: [{
                    required: true,
                    message: "请选择报告类型",
                    trigger: "change"
                }],
                roleIds: [{
                    required: true,
                    message: "请选择报告角色",
                    trigger: "change"
                }],
                description: [{
                    required: true,
                    message: "请填写报告描述",
                    trigger: "blur"
                }],
                aiModel: [{
                    required: true,
                    message: "请选择报告模型",
                    trigger: "change"
                }],
            },
            // 班型列表
            classList: [],
            rolesOptions: [],
            typeOptions: [],
            editIdentifierList: [],
            fileList: [],
            modelVal: '',
            modelList: [],
            workList: [],
            weekVal: '',
            weekList: [],
            testList: [],
            reportList:[],
            aiModelList:[]
        };
    },
    methods: {
        // 切换用户组
        changeUserGroup(data) {
            console.log(data);
            this.form.trancheId = data.trancheId;
            this.form.projectId = data.projectId;
            this.form.userGroupIds = data.userGroupArr;
            // console.log(this.form);
        },
        handleCheckAllChange(val) {
            // console.log(val)
            if (val) {
                this.rolesOptions.map(item => {
                    this.form.roleIds.push(item.id);
                })
            } else {
                this.form.roleIds = [];
            }
            this.form.isIndeterminate = false;
        },
        handleCheckedRolesChange(value) {
            // console.log(value);
            let checkedCount = value.length;
            this.form.checkAll = checkedCount === this.rolesOptions.length;
            this.form.isIndeterminate = checkedCount > 0 && checkedCount < this.rolesOptions.length;
            // console.log('this.form.isIndeterminate',this.form.isIndeterminate)
        },
        // 提交表单
        async commitForm() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let submitForm = {
                id: this.$route.params.id,
                title: this.form.title,
                type: this.form.type == '-1' ? this.form.type2 : this.form.type,
                projectId: this.$store.state.user.currentProject.id,
                description: this.form.description,
                descriptionFiles: this.fileMd5List,
                questIds: this.form.checkQuest ? this.form.worksIds.toString() : '',
                modelId: this.modelVal,
                periodId: this.weekVal,
                surveyIds: this.form.test ? this.form.testIds.toString() : '',
                checkSurvey: this.form.test ? 1 : 0,
                phaseDateStart: this.form.phaseDateTime.length > 0 ? this.form.phaseDateTime[0] : '',
                phaseDateEnd: this.form.phaseDateTime.length > 0 ? this.form.phaseDateTime[1] : '',
                phaseSubmitteStart: this.form.phaseSubmitteTime.length > 0 ? this.form.phaseSubmitteTime[0] : '',
                phaseSubmitteEnd: this.form.phaseSubmitteTime.length > 0 ? this.form.phaseSubmitteTime[1] : '',
                questDateStart: this.form.questDateTime.length > 0 ? this.form.questDateTime[0] : '',
                questDateEnd: this.form.questDateTime.length > 0 ? this.form.questDateTime[1] : '',
                questSubmitteStart: this.form.questSubmitteTime.length > 0 ? this.form.questSubmitteTime[0] : '',
                questSubmitteEnd: this.form.questSubmitteTime.length > 0 ? this.form.questSubmitteTime[1] : '',
                modelType:this.form.aiModel
            }
            this.$set(submitForm, 'roleIds', this.form.roleIds.toString())
            this.$set(submitForm, 'checkPhase', this.form.checkPhase ? 1 : 0)
            this.$set(submitForm, 'checkQuest', this.form.checkQuest ? 1 : 0)
            if (this.form.type == 3) {
                submitForm.groupString = JSON.stringify(this.form.userGroupIds)
            }
            if (this.$route.query.isCreate == 'false') {
                let resData = await this.$Api.Project.reportEditInfo(submitForm);
                // console.log(resData);
                if (resData.code == 200) {
                    loading.close();
                    this.$notify.success({
                        title: "编辑成功",
                        // message: resData.msg
                    });
                    this.$router.go(-1)
                } else {
                    loading.close();
                    this.$notify.error({
                        title: "编辑失败",
                        message: resData.msg
                    });
                }
            } else {
                let resData = await this.$Api.Project.editReport(submitForm);
                // console.log(resData);
                if (resData.code == 200) {
                    loading.close();
                    this.$notify.success({
                        title: "编辑成功",
                        // message: resData.msg
                    });

                    this.$router.push({
                        path: `/manager/report/create_kimi?breadNumber=4`,
                        query: {
                            reportId: resData.data.reportId,
                            rootMsgId: resData.data.rootMsgId,
                            breadNumber: 4,
                            reportType: this.form.type,
                            isView: false
                        }
                    })
                } else {
                    loading.close();
                    this.$notify.error({
                        title: "编辑失败",
                        message: resData.msg
                    });
                }
            }

        },
        //创建课程
        async onSubmit() {
            this.$refs['createForm'].validate((valid) => {
                // console.log('valid',valid);
                if (valid) {
                    this.commitForm();
                } else {
                    this.$message.error({
                        title: "错误",
                        message: "请正确填写数据"
                    });
                }
            })
        },
        // 获取报告类型Option
        async getReportOption() {
            let pramas = {
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportOption(pramas);
            console.log('选项数据', resData.data);
            this.typeOptions = resData.data.type;
            this.rolesOptions = resData.data.role;
            this.aiModelList = resData.data.aiModel;
        },
        async queryReport() {
            let pramas = {
                id: this.$route.params.id,
            }
            let resData = await this.$Api.Project.queryReport(pramas);
            console.log('报告数据', resData.data);
            this.form.aiModel = resData.data.modelType;
            this.form.title = resData.data.title;
            this.form.type = resData.data.type == 4 ? '-1' : resData.data.type == 7 ? '-1' : resData.data.type;
            this.form.type2 = resData.data.type;
            this.form.description = resData.data.description;
            this.form.phaseDateTime = resData.data.phaseDateStart ? [resData.data.phaseDateStart, resData.data.phaseDateEnd] : '';
            this.form.phaseSubmitteTime = resData.data.phaseSubmitteStart ? [resData.data.phaseSubmitteStart, resData.data.phaseSubmitteEnd] : '';
            this.form.questDateTime = resData.data.questDateStart ? [resData.data.questDateStart, resData.data.questDateEnd] : '';
            this.form.questSubmitteTime = resData.data.questSubmitteStart ? [resData.data.questSubmitteStart, resData.data.questSubmitteEnd] : '';
            this.form.checkPhase = Boolean(resData.data.checkPhase);
            this.form.checkQuest = Boolean(resData.data.checkQuest);
            this.form.checkReport = Boolean(resData.data.checkReport);
            this.form.userGroupIds = JSON.parse(resData.data.groupString);
            this.userGroupVal = resData.data.groupString ? JSON.parse(resData.data.groupString) : [];
            this.weekVal = resData.data.periodId;
            this.form.test = resData.data.type == 4 ? true : resData.data.type == 7 ? true : false;
            let roles = [];
            resData.data.roleIds.split(",").map(item => {
                roles.push(Number(item));
            })
            this.form.roleIds = roles
            this.editIdentifierList = resData.data.fileInfo
            this.editIdentifierList.map(item => {
                item.name = item.filename
            })
            this.modelVal = resData.data.modelId;
            // console.log('--', resData.data.questIds.split(','))
            if (resData.data.questIds) {
                this.form.worksIds = resData.data.questIds.split(',').map(Number)
            }
            if (resData.data.type == 4 || resData.data.type == 7) {
                this.form.testIds = resData.data.surveyIds.split(',').map(Number)
            }
            if(resData.data.type == 1 || resData.data.checkReport){
                this.form.reportIds = resData.data.reportIds.split(',').map(Number)
            }
            // console.log('处理后的数据', this.editIdentifierList)
        },
        selectChange(val) {
            // console.log(`%c ${val}`, 'color:green');
            this.form.description = (this.modelList.filter(item => item.templateType == val)[0].content).replace(/\\n/g, '\n');
        },
        // 获取穿梭框作业列表
        async listByProject() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
                startTime: this.form.questDateTime ? this.form.questDateTime[0] : '',
                endTime: this.form.questDateTime ? this.form.questDateTime[1] : ''
            }
            let resData = await this.$Api.Project.listByProject(data);
            // console.log('作业列表:', resData);
            resData.data.map(item => {
                item.disabled = false
            })
            this.workList = resData.data
        },
        async queryStageCycleList() {
            let data = {
                projectId: this.$store.state.user.currentProject.id
            }
            let resData = await this.$Api.kimi.queryStageCycleList(data);
            // console.log('获取周列表:', resData);
            this.weekList = resData.data
        },
        // 选择周
        selectWeek(data) {
            // console.log('选择周:', data)
        },
        // 获取AI模板
        async reportTemplate() {
            let data = {
                projectId: this.$store.state.user.currentProject.id
            }
            let resData = await this.$Api.kimi.reportTemplate(data);
            // console.log('AI模板:', resData);
            this.modelList = resData.data
        },
        // 获取穿梭框前测列表
        async listAdmin() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
                page: 1,
                limit: 1000,
                queryKey: ''
            }
            let resData = await this.$Api.wjx.listAdmin(data);
            // console.log('前测列表:', resData);
            resData.data.records.map(item => {
                item.disabled = false
            })
            this.testList = resData.data.records;
        },
        // 获取穿梭框报告列表
        async getReportList() {
            let pramas = {
                page: 1,
                limit: 100,
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportList(pramas);
            console.log('穿梭框报告列表', resData.data);
            this.reportList = resData.data.records
        }
    },
    created() { },
    mounted() {
        this.queryReport();
        this.getReportOption();
        this.listByProject();
        this.queryStageCycleList();
        this.reportTemplate();
        this.listAdmin();
        this.getReportList()
    }
};
</script>

<style lang="less" scoped>
@import "../../manage/manage_project/less/manage-project.less";

/deep/ .el-radio {
    margin-bottom: 10px;
}
</style>